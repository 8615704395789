import './App.css';
import { Route, Routes } from 'react-router-dom';
import Home from './pages/Home';
import AppView from './pages/ChatView';

function App() {
  return (
    <Routes>
      <Route exact path='/' element={<Home />} />
      <Route exact path='/chat' element={<AppView />} />
    </Routes>
  );
}

export default App;
