import React, { useEffect, useState } from 'react';
import Navbar from '../components/Navbar';
// import dotenv from 'dotenv';
// dotenv.config();

const { Configuration, OpenAIApi } = require('openai');
const configuration = new Configuration({
  apiKey: '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'
  // apiKey: process.env.REACT_API
});
const openai = new OpenAIApi(configuration);

const AppView = () => {
  const [messages, setMessages] = useState([
    {
      role: 'assistant',
      content:
        'The page looks like it is still loading. Please wait a minute or two. After finishing loading, this message will get overwritten.'
    }
  ]);
  if (localStorage.getItem('chats') === null) {
    localStorage.setItem(
      'chats',
      JSON.stringify([
        {
          role: 'assistant',
          content: 'Welcome to AITalk!'
        }
      ])
    );
  }
  useEffect(() => {
    setMessages([
      {
        role: 'assistant',
        content: 'Welcome to AITalk!'
      }
    ]);
    setMessages(JSON.parse(localStorage.getItem('chats')));
  }, []);
  useEffect(() => {
    // storing input name

    localStorage.setItem('chats', JSON.stringify(messages));
  }, [messages]);

  return (
    <div className='app'>
      <Navbar />
      <Chatbox
        title='Chat #1'
        user='ChatGPT'
        userImg='https://unsplash.it/200'
        texts={messages}
        setMessages={setMessages}
      />
    </div>
  );
};
var image;
const Chatbox = ({ title, user, userImg, texts, setMessages }) => {
  const [inputText, setInputText] = useState('');
  const submition = async (event) => {
    event.preventDefault();
    if (
      inputText.startsWith('!image') ||
      (inputText.includes('make') && inputText.includes('image')) ||
      (inputText.includes('make') && inputText.includes('picture')) ||
      (inputText.includes('draw') && inputText.includes('picture')) ||
      (inputText.includes('draw') && inputText.includes('image')) ||
      (inputText.includes('create') && inputText.includes('picture')) ||
      (inputText.includes('create') && inputText.includes('image'))
    ) {
      const response = await openai.createImage({
        prompt: inputText,
        n: 1,
        size: '512x512'
      });
      console.log(response.data.data[0].url);
      image = response.data.data[0].url;
      setMessages([
        ...texts,
        { role: 'user', content: inputText },
        {
          role: 'assistant',
          content: `Hi, heres my response to: ${inputText}!__url::${image}::__`
        }
      ]);
    } else {
      var completion = await openai.createChatCompletion({
        model: 'gpt-3.5-turbo',
        messages: [...texts, { role: 'user', content: inputText }]
      });
      console.log(completion.data.choices[0].message);
      setMessages([
        ...texts,
        { role: 'user', content: inputText },
        {
          role: 'assistant',
          content: `${completion.data.choices[0].message.content}`
        }
      ]);
    }

    setInputText('');
    image = undefined;
    // console.log(response.data.choices[0].text);
  };

  // Local storage wonder stuff girl

  return (
    <div className='chat'>
      <div className='topbar'>
        <div>
          <h2>{title}</h2>
          <p>{user}</p>
        </div>
        <img src={userImg} alt={user} />
      </div>
      <div className='chatBox'>
        {texts.map((text) => (
          <div className='textBubble'>
            {text.role === 'user' && (
              <img src={userImg} alt='' className='userImg' />
            )}
            <span>
              {!text.content.includes('__url::')
                ? text.content
                : 'Here is my image response.'}
            </span>
            {text.content.includes('__url::') && (
              <img
                src={text.content.substring(
                  text.content.indexOf('__url::') + 7,
                  text.content.lastIndexOf('::__')
                )}
                alt='INVALID'
                className='generatedImage'
                draggable='false'
              />
            )}
          </div>
        ))}
      </div>
      <form onSubmit={submition}>
        <input
          type='text'
          className='chatInput'
          placeholder='Ask me something!'
          value={inputText}
          onChange={(event) => setInputText(event.target.value)}
        />
        <button type='submit' className='button orange'>
          Submit
        </button>
      </form>
    </div>
  );
};
export default AppView;
