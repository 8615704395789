import React from 'react';
import { Link } from 'react-router-dom';
import Navbar from '../components/Navbar';
import laptopImg from '../images/laptop.png';
import powderExplosionImg from '../images/powder.png';

const Home = () => {
  return (
    <>
      <Navbar />
      <LandingPage />
    </>
  );
};
function LandingPage() {
  return (
    <div className='landingPage'>
      <div className='leftLandingPageSection'>
        <h1>
          The <span className='orange-color'>MOST</span> <br /> Advanced{' '}
          <span className='orange-color'>AI Image</span> <br /> Generation{' '}
          <span className='orange-color'>Chatbot</span>
        </h1>
        <p>
          AITalk is a revolutionary new chatbot created by @Foxxo and @Nimo.
          This chatbot is capable of generating images from text when asked to
          do so. It uses advanced artificial intelligence algorithms to
          interpret the text and generate a corresponding image. The images
          generated by AITalk are incredibly realistic and can be used for a
          variety of purposes, such as creating logos, designing websites, and
          more. AITalk is the most advanced AI generation chatbot available on
          the market today and is sure to revolutionize the way people interact
          with technology.
        </p>
        <Link to='/chat' className='button orange pill '>
          Start Now!
        </Link>
      </div>
      <div className='rightLandingPageSection'>
        <img src={laptopImg} id="laptop" alt='' />
        <img src={powderExplosionImg} id="powder" alt='' />
      </div>
    </div>
  );
}


export default Home;
