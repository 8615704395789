import React from 'react';
import { Link } from 'react-router-dom';
import '../App.css';
const Navbar = () => {
  const toggle = () => {
    document.getElementById('nav').classList.toggle('open');
  };
  return (
    <div className='nav' id='nav'>
      <button className='navAction' onClick={toggle}>
        X
      </button>
      <h2>AITALK</h2>
      <div>
        <Link to='/' className='navLink'>
          Home
        </Link>
        <hr />
        <Link to='/chat' className='navLink'>
          Go To Chats
        </Link>
      </div>
      <Link to='/chat' className='button orange pill signup'>
        New Chat
      </Link>
    </div>
  );
};

export default Navbar;
